import { recipeIdSelector } from './common/selectors'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { defaultViewAngleId } from '../viewAngles'

type ShareType = 'facebook' | 'twitter' | 'pinterest' | 'email'

export function getShareUrl(
  shareType: ShareType,
  state: Record<string, unknown> & { parentHref?: string | null | undefined },
): string {
  const recipeId = recipeIdSelector(state)

  const recipeUrl = encodeURIComponent(state.parentHref || window.location.href)
  const apiOrigin = window.location.origin

  const title = encodeURIComponent(
    `My ${document.title.split(' - ').reverse().join(' - ')}`,
  )

  switch (shareType) {
    case 'facebook':
      // Facebook does not work with localhost.
      return `https://facebook.com/sharer/sharer.php?u=${recipeUrl}`

    case 'twitter': {
      const { hashtag, twitterHandle } = window.serverConfig ?? {}
      let shareUrl = `https://twitter.com/intent/tweet?url=${recipeUrl}&text=${title}`
      if (twitterHandle) {
        shareUrl += `&via=${twitterHandle}`
      }
      if (hashtag) {
        shareUrl += `&hashtags=${hashtag}`
      }
      return shareUrl
    }

    case 'pinterest': {
      const media = encodeURIComponent(
        `${apiOrigin}/api/recipes/${recipeId}/${defaultViewAngleId}?modelVersion=0`,
      )
      return `https://pinterest.com/pin/create/button/?url=${recipeUrl}&media=${media}&description=${title}`
    }

    case 'email':
      return `mailto:?subject=${title}&body=${recipeUrl}`
  }
}
