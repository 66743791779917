import fp from 'lodash/fp.js'
import { createSelector } from 'reselect'

import { parseSearch } from '../history'
import { ControlTree, ControlTreeNode } from '../control-tree/types'
import { GetRecipeTitle, GetSkuTitle, GetIndexTitle } from './types'

// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import customizer from '../../customizer.cjs'

// For ESM/CJS portability
const { DEFAULT_MENU, DEFAULT_MENU_WHEN_SKU } = customizer

const sheetSelector = fp.get('sheets')
const sheetListsSelector = fp.get('sheetLists')

const locSelector = fp.get('history.location')
const matchesSelector = fp.get('history.matches')

const pathSelector = createSelector([locSelector], fp.get('pathname'))
const querySelector = createSelector([locSelector], (loc) =>
  loc ? parseSearch(loc.search) : {},
)

const isSavedSelector = createSelector(
  [pathSelector, querySelector],
  (path, query) => path !== '/' && !('changes' in query),
)

const isSkuSelector = createSelector([pathSelector], fp.startsWith('/sku/'))

const skuSelector = createSelector(
  [pathSelector, isSkuSelector],
  (path, isSku) => (isSku ? fp.replace('/sku/', '', path) : null),
)

const menuSegmentSelector = createSelector([querySelector], (q) =>
  q.menu ? (q.menu as string).split('/') : [],
)

const createMenuSelector = (controlTree: ControlTree) =>
  createSelector(
    [menuSegmentSelector, isSkuSelector, controlTree.getNodes],
    (segs, isSku, nodes) => {
      if (segs.length >= 1) return segs[0]
      if (isSku) {
        return typeof DEFAULT_MENU_WHEN_SKU === 'function' ?
            DEFAULT_MENU_WHEN_SKU(nodes)
          : DEFAULT_MENU_WHEN_SKU
      }
      return DEFAULT_MENU
    },
  )

const submenuSelector = createSelector([menuSegmentSelector], (segs) =>
  segs.length >= 2 ? segs[1] : null,
)

const changesSelector = createSelector([querySelector], ({ changes }) =>
  changes ? JSON.parse(changes as string) : {},
)

const recipeIdSelector = createSelector([matchesSelector], fp.get('recipeId'))

const withChangesSelector = createSelector(
  [matchesSelector],
  (matches) =>
    matches && 'recipeId' in matches && matches.status === 'with-changes',
)

const isRecipeFinalizedSelector = createSelector(
  [recipeIdSelector, withChangesSelector],
  (recipeId, withChanges) => !!(recipeId && !withChanges),
)

const isRecipeReviewSelector = createSelector(
  pathSelector,
  fp.startsWith('/review/'),
)

const pageTitleSelector = (
  controlTree: ControlTree,
  getRecipeTitle: GetRecipeTitle,
  getSkuTitle: GetSkuTitle,
  getIndexTitle: GetIndexTitle,
  state: Record<string, unknown>,
) => {
  const recipeId = recipeIdSelector(state)
  const sku = skuSelector(state)

  let title
  if (recipeId) {
    const hasChanges = withChangesSelector(state)
    const recipe = controlTree.getRecipe(state)

    title = getRecipeTitle(
      window.serverConfig,
      { data: recipe },
      recipeId,
      hasChanges,
    )
  } else if (sku) {
    const nodes = controlTree.getNodes(state)
    title = getSkuTitle(window.serverConfig, [sku, nodes])
  } else {
    title = getIndexTitle(window.serverConfig)
  }

  return title
}

// TODO: This is mizuno-uniforms only and should be removed from the platform.
const garmentFilterSelector = fp.get('garmentFilter')

// TODO: This is mizuno-uniforms only and should be removed from the platform.
const garmentGroupSelector = createSelector([sheetSelector], (sheets) => {
  const groupIds = fp.map('garmentGroupId', sheets.garments)
  return fp.filter(({ id }) => fp.includes(id, groupIds))(sheets.garmentTypes)
})

// TODO: This is mizuno-uniforms only and should be removed from the platform.
const filteredGarmentSelector = createSelector(
  [sheetSelector, garmentFilterSelector],
  (sheets, garmentGroupId) => fp.filter({ garmentGroupId })(sheets.garments),
)

type ViewAngles<Areas extends string = string> = Record<
  Areas,
  {
    label: string
    exposeForUI: boolean
    exposeForRecipePreviewImages: boolean
    previewSize?: { height: number }
  }
>

const getPreviewUrlsSelector =
  (viewAngles: ViewAngles, defaultViewAngleId: string) =>
  (state: { previewUrls: Record<string, unknown> }) =>
    fp.pipe(
      fp.pickBy({ exposeForUI: true }),
      fp.omit(defaultViewAngleId),
      fp.keys,
      (angleIds) => fp.concat([defaultViewAngleId], angleIds),
      fp.map((angleId) => [angleId, (state.previewUrls || {})[angleId]]),
      fp.fromPairs,
      fp.omitBy(fp.isUndefined),
    )(viewAngles)

const previewUrlsSelector = fp.get('previewUrls')

const previewUrlsWithOriginSelector = createSelector(
  [previewUrlsSelector],
  fp.mapValues((url: string) =>
    url.includes('://') ? url : window.location.origin + url,
  ),
)

const legacyPreviewUrlsWithOriginSelector = createSelector(
  [previewUrlsWithOriginSelector],
  fp.mapKeys((k: string) => `preview${fp.upperFirst(k)}`),
)

// checks for a single @ and at least one dot after it
const emailRegex = /^[^@]+@[^@]+\.[^@]+$/

const isContactFormValidSelector = ({
  contactForm,
}: {
  contactForm: {
    email: string
    region: string
    turingTest: string
  }
}) =>
  contactForm &&
  emailRegex.test(contactForm.email) &&
  (window.serverConfig?.regions ? contactForm.region : true) &&
  contactForm.turingTest.length === 0

const isAppLoadingSelector = (state: {
  isRecipeLoading: boolean
  isRecipeSaving: boolean
  isOrderingRecipe: boolean
}) => state.isRecipeLoading || state.isRecipeSaving || state.isOrderingRecipe

const layoutModeSelector = (state: { layoutMode: string }) => state.layoutMode

const isEmbedSelector = (state: { parentHref: string }) => !!state.parentHref

const _getNodeLegacyValue = (node: ControlTreeNode) => {
  if (!node.isAvailable || !node.value) {
    return 'None'
  }
  if (node.legacyValue) {
    return node.legacyValue
  }
  if (node.optionName) {
    return node.optionName
  }
  if (fp.isArray(node.value)) {
    return fp.map(({ name }) => name, node.value).join(', ')
  }
  // @ts-expect-error fp.isObject should probably be replaced with typeof ... === 'object'; that would also make the cast in next line unnecessary
  if (fp.isObject(node.value) && node.value.name) {
    return (node.value as { name: string }).name
  }
  return node.value
}

const createLegacyRecipeDataSelector = (controlTree: ControlTree) =>
  createSelector(
    controlTree.getNodes,
    sheetListsSelector,
    (nodes, { propDefs }) =>
      fp.fromPairs(
        // TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error not sure how to fix right now
        fp.map(
          ({ legacyPath, id }) => {
            const nodePath = id.replace(/_/g, '.')
            const node = nodes[nodePath]
            return [legacyPath, _getNodeLegacyValue(node)]
          },
          fp.filter((propDef) => propDef.legacyPath, propDefs),
        ),
      ),
  )

const createLegacyRecipeDataFlatSelector = (controlTree: ControlTree) =>
  createSelector(
    createLegacyRecipeDataSelector(controlTree),
    fp.mapKeys(fp.replace(/\./g, '-')),
  )

const createLegacyRecipeDataNestedSelector = (controlTree: ControlTree) =>
  createSelector(createLegacyRecipeDataSelector(controlTree), (recipeData) =>
    fp.reduce((a, [k, v]) => fp.set(k, v, a), {}, fp.toPairs(recipeData)),
  )

export {
  changesSelector,
  emailRegex,
  filteredGarmentSelector,
  garmentFilterSelector,
  garmentGroupSelector,
  getPreviewUrlsSelector,
  isAppLoadingSelector,
  isContactFormValidSelector,
  isEmbedSelector,
  isRecipeFinalizedSelector,
  isRecipeReviewSelector,
  isSavedSelector,
  isSkuSelector,
  layoutModeSelector,
  legacyPreviewUrlsWithOriginSelector,
  createLegacyRecipeDataFlatSelector,
  createLegacyRecipeDataNestedSelector,
  locSelector,
  matchesSelector,
  menuSegmentSelector,
  createMenuSelector,
  pageTitleSelector,
  pathSelector,
  previewUrlsWithOriginSelector,
  querySelector,
  recipeIdSelector,
  sheetSelector,
  skuSelector,
  submenuSelector,
  withChangesSelector,
}
